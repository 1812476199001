<template>
  <div>
    <b-card >
      <b-row class="text-center">
        <b-col sm="12" md="3">
          <img
            width="25%"
            style="border-radius:50%;"
            src="@/assets/saniy.png"
            alt=""
          />

          <b-card-text>
            <p class="school32 text-center">Садриддин Aйний <br />(1878 -1954)</p></b-card-text
          >
        </b-col>
        <b-col md="6">
           <b-card-text>
          <p class="TextBlack17">
            Садриддин Aйний - ёзувчи, олим ва ўзбек ҳамда тожик адабиётининг ХХ
            аср жамоат арбоби. Aйний ўзбек ва тожик адабиётида ҳам тарихшунос,
            ҳам адабиёцҳунос ва олим сифатида илмий-тадқиқий ишларни олиб
            борган. Рудакий, ибн Сино, Саъди, Васифий, Бедил, Навоий, Aҳмад
            Дониш каби шоир ва олимлар ҳақида романлар ёзган. 1878 йил Бухоро
            вилоятининг Ғиждувон тумани, Соктари қишлоғида туғилган. Унинг отаси
            тегирмон устаси, додаси еса мулла бўлган. Мактабни битириб, у ўқишни
            ва ёзишни билган. Садриддин мадрасада таълим берилувчи китобларга
            нисбатан, кўпроқ шеърият билан қизиққан. 1892 йил бошларида Шарифжон
            Маҳдум уни ўз уйида яшашга таклий қилади. Бу уйда адабиёт ва
            санъатга яқин одамлар кўришар, суҳбатлар қуриб, китоб мутоаала
            қилишарди. У вақтлар унинг ўзи ҳам турли таҳаллусларда шеърлар
            ёзарди. “Aйний” таҳаллусини у 1896 йил олган. 1905 йил машҳур шоир
            бўлгач, унинг шеърлари сайланма асарлар бўпламига киритилган. У
            вақтлар ташкил қилинган “Маърифат” жамияти “янги услубдаги”
            мактаблар (маҳаллий) учун дарсликлар ёзган. Жадидларга яқинлиги
            боис, 1915 йил у шубҳа остига тушиб, таъқиб қилинади ва ҳукуматдан
            яшириниб, пахта заводида ишлашига тўғри келади. Шунга қарамай, 1916
            йил Aйнийни кутилмаганда Бухоронинг енг катта мадрасалардан бири
            Ҳиёбонга мударрис етиб чақиришади, аммо бу таклифни у рад етади.
            1917 йил 8 апрел куни Бухородаги ўзгаришлар туфайли, жадидчилар
            намойиш уюштиришади. Оммавий ҳибсга олиш жараёнлари бошланади. Aйний
            “ҳурфикр” сифатида 75 маротаба ҳимчинда уриш орқали жазоланиб,
            “Обхона” зиндонига ҳибсга ташлашади, у йердан ҳеч ким тирик
            чиқмаган. Уни русслар қутқаришади. Укаларидан бири Сирожиддин
            амирнинг ҳукми билан қатл етилади, Муҳиддин еса босмачлар томонидан
            ўлдирилади. Садриддин Aйний болшевикларга ачинган, оммада кенг
            танилган инқилоб мавзусидаги шеърлар ёзган, Бухорода инқилобий
            чиқишларда ёрдам берган. 1920 йил уйланиб, рафиқаси билан
            Самарқандга кетади. Инқилобий ғалабадан сўнг айрим вақт мактабда
            дарс беради, сўнгра, бутунлай адабий фаолият билан шуғулланади.
            Совет вақтида, асосан, адабий фаолият билан машғул бўлган. 1929 йил
            Тожик ССР Марказий Сайлов Ҳайъати аъзоси, 1934 йил еса СССР
            Ёзувчилар уюшмаси раҳбарияти аъзоси етиб сайланади. Илк бор “Тожик
            адабиёти намуналари” номли тожик миллий ижодий сайланмасини тузади.
            Тожик ССРда хизмат кўрсатган фан арбоби (1940), Ўзбек ССР ФAнинг
            шарафли аъзоси (1949), филология фанлари доктори (1949). 1951 йилдан
            Тожик ССР ФAнинг биринчи президенти. Тожикистон Давлат мукофотининг
            биринчи лауреати (1950). Садриддин Aйний ўзининг она тили
            ҳисобланмиш тожик тили билан бирга, ўзбек тилини ҳам яхши билган ва
            ўзининг айрим асарларини шу тилда ёзган. У икки халқ адабиётига ҳам
            сезиларли ҳисса қўшган. Унинг асосий меҳнатлари: “Одина” (1924 йил
            нашр етилган), “Дохунда” (1930 йил нашр етилган), “Қуллар” (1934),
            “Судхўрнинг ўлими” (1939), “Хотиралар” (“Бухоро”) (1949—1954).
            “Одина” қиссаси янги тожик адабиётининг боши ҳисобланади. Рудакийдан
            ХХ аср бошларигача бўлган шеъриятнинг енг яхши намуналарини ўз ичига
            олган “Тожик адабиёти намуналари” номли тожик миллий ижодий
            сайланмасини тузган. Ушбу нашр орқали Aйний тожикларнинг алоҳида
            миллат сифатида мавжудлигини исботлаган. манфаатдор бўлган. Гарчи, у
            бундай айбланишларга дучор бўлса-да (масалан, Н Шоирнинг ўзи “тарихи
            далиллар асосидаги асар пантурклар ҳақидаги зўравонлик ва изланишлар
            асосидаги гумонларни бартараф етди ва уларни муҳрлади...” Aйний 1941
            йил Темурий, шунингдек, мумтоз шеъриятнинг буюк асосчиси Рудакий
            қабрини очишда қатнашган. Сталин, Максим Горкий, Ёқуб Колас, Юлиус
            Фучиклар билан кўришган. Сайдирддин Aйний 1954 йил 15 июл куни
            Сталинободда вафот етган, ҳозирги Душанбе.
          </p>
        </b-card-text>
        </b-col>
        <b-col md="3"></b-col>
      </b-row>
    </b-card>
    <!-- <b-card style="background-color:#EEEEEE">
      <b-container style="background-color:#EEE4CD;border-radius:20px">
       
      </b-container>
      <b-container>
        <b-row>
          <b-col>
            <b-button
              @click="$router.push({ name: 'jadidchilar_info' })"
              variant="outline-danger"
            >
              {{ $t("Back") }}
            </b-button>
          </b-col>
          <b-col sm="1">
            <b-button
              @click="$router.push({ name: 'toshpolatbek' })"
              style="text-align:right"
              variant="outline-success"
            >
              {{ $t("Next") }}
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </b-card> -->
  </div>
</template>
<script>
export default {};
</script>
<style></style>
